import React from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"
import generalClasses from "./general.module.css"

import appImage from "../../images/case-studies/ereporter.jpg"
import Footer from "../../components/footer"

import { Link } from "gatsby"
import arrowLeft from "../../images/arrow-left.svg"
import RightIcon from "../../images/arrow-right.inline.svg"

const Ereporter = props => (
  <HomePageLayout pageTitle="EREPORTER" background>
    <SEO title="EReporter Case Study" />
    <main className={generalClasses.main}>
      <Link to={"/solutions/projects"} className={generalClasses.backLink}>
        <img src={arrowLeft} alt="" className={generalClasses.backLinkImage} />
        Projects
      </Link>
      <section
        className={[generalClasses.caseStudyIntro, generalClasses.section].join(
          " "
        )}
      >
        <div>
          <h1 className={generalClasses.caseStudyHeader}>EREPORTER</h1>
          <p className={generalClasses.caseStudyDescription}>
            eReporter is an election monitoring and incidence reporting tool
            built for Nigeria's Independent National Electoral Commission (INEC)
            that helps to citizens to report incidences encountered at the
            polling unit in real time
          </p>
          <p className={generalClasses.caseStudyCategory}>
            UI / UX | MOBILE APP DEVELOPMENT
          </p>
        </div>
        <ul className={generalClasses.caseStudyDetails}>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>CLIENT</h4>
            <p className={generalClasses.caseStudyDetailContent}>INEC</p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>LOCATION</h4>
            <p className={generalClasses.caseStudyDetailContent}>Nigeria</p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>USERS</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              1000 - 1,000,000 Users
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>INDUSTRY</h4>
            <p className={generalClasses.caseStudyDetailContent}>Government</p>
          </li>
        </ul>
      </section>
      <section className={generalClasses.section}>
        <h1 className={generalClasses.meetCaseStudyHeader}>MEET INEC</h1>
        <p className={generalClasses.meetCaseStudyText}>
          The Independent National Electoral Commission (INEC) was established
          by the 1999 Constitution of the Federal Republic of Nigeria to among
          other things organize elections into various political offices in the
          country. One of the major functions of INEC is to conduct election
          within the country and also ensure the election is peaceful.
        </p>
      </section>
      <section
        className={[generalClasses.grid, generalClasses.section].join(" ")}
      >
        <div>
          <h1 className={generalClasses.sectionHeader}>our idea</h1>
          <p className={generalClasses.sectionText}>
            We carried out a research and discovered that quite a number of
            negative incidences occur during elections at various polling booths
            across the country. We itemized a number of election incidences and
            grouped them into categories to make reporting seamless and easy.
          </p>
        </div>
        <div>
          <h1 className={generalClasses.sectionHeader}>Result</h1>
          <p className={generalClasses.sectionText}>
            We created a mobile application and also a web dashboard that is
            quite easy to use. Incidences can be reported either by audio, video
            or by writing texts on the platform with a prefilled geo-location
            tracker incorporated in the application. The web dashboard is used
            to manage the reported incidences.
          </p>
        </div>
      </section>
      <img src={appImage} className={generalClasses.displayImageFull} alt="" />
      <div className={generalClasses.caseStudyPageLinks}>
        <Link
          to="/case-studies/revenue-reporting-tool"
          className={[
            generalClasses.caseStudyPageLink,
            generalClasses.caseStudyPageLinkRight,
          ].join(" ")}
        >
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>NEXT</span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              Revenue Reporting Tool
            </span>
          </p>
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <RightIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
        </Link>
      </div>
    </main>
    <Footer />
  </HomePageLayout>
)

export default Ereporter
